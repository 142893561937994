import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/styles"
import theme from "../../src/theme"

const FullHeightWrapper = styled.div`
  min-height: 100vh;

  > div {
    min-height: 100vh;
  }
`

export const TopLayout = (props) => (
  <React.Fragment>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"
        rel="stylesheet"
      />
      <link rel="shortcut icon" href="/favicon.png" />
      <meta
        name="description"
        content="Demos is de jongste studentenvereniging van Eindhoven en onderscheidt zich door zijn gelijkwaardigheid van leden."
      />
      <meta
        name="google-site-verification"
        content="HQTkbPc9TysMpRgXlfSTPveJE3D9paeGv77-5wzzKzI"
      />
      <html lang="nl" />
    </Helmet>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <FullHeightWrapper>{props.children}</FullHeightWrapper>
    </ThemeProvider>
  </React.Fragment>
)

TopLayout.propTypes = {
  children: PropTypes.node,
}
