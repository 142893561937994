module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demos Webiste","short_name":"Demos.nl","start_url":"/","background_color":"#2E3C57","theme_color":"#0898c4","include_favicon":false,"display":"browser","icon":"src/assets/demos-icon.svg","icon_options":{"purpose":"maskable"},"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"b990bc255583a72fa5e656382a44930c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-140489403-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/inschrijven/"]},
    }]
