import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import React from "react"

export const createDarkTheme = (theme, dark = true) => {
  const {
    common,
    primary,
    secondary,
    error,
    warning,
    info,
    success,
    grey,
    contrastThreshold,
    tonalOffset,
  } = theme.palette

  return createMuiTheme({
    ...theme,
    palette: {
      common,
      primary,
      secondary,
      error,
      warning,
      info,
      success,
      grey,
      contrastThreshold,
      tonalOffset,
      type: dark ? "dark" : "light",
    },
  })
}

export const DarkThemeProvider = (props) => {
  return (
    <ThemeProvider theme={(theme) => createDarkTheme(theme, props.dark)}>
      {props.children}
    </ThemeProvider>
  )
}
