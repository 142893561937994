import red from "@material-ui/core/colors/red"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import { accent, background, white, dark } from "./colors"
export { DarkThemeProvider } from "./components/DarkThemeProvider"
export { RadiusProvider } from "./components/RadiusProvider"

// A custom theme for this app
const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: ["Montserrat", "sans-serif"],
      body1: {
        fontWeight: 400,
      },
      h2: {
        fontWeight: 200,
      },
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 300,
        letterSpacing: 0,
      },
      body2: {
        fontWeight: 600,
        letterSpacing: 0,
      },
    },
    palette: {
      primary: {
        main: accent,
      },
      secondary: {
        main: white,
      },
      inherit: {
        main: white,
      },
      error: {
        main: red.A400,
      },
      background: {
        main: background,
      },
    },
    shape: {
      cardBorderRadius: 8,
    },
  })
)

export default theme
